import { AppConfig, AuthConfig } from '@rf-smart-for-oraclecloud/platform-ui';

const auth: AuthConfig = {
    domain: import.meta.env.VITE_AUTH0_DOMAIN,
    clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
    audience: import.meta.env.VITE_AUTH0_AUDIENCE,
    scope: import.meta.env.VITE_AUTH0_SCOPE,
};

const apis = {
    accounts: import.meta.env.VITE_ACCOUNTS_API_URL,
    workflows: import.meta.env.VITE_WORKFLOW_API_URL,
    oracle: import.meta.env.VITE_ORACLE_API_URL,
    mock: import.meta.env.VITE_MOCK_API_URL,
};

type ApiTypes = {
    [Property in keyof typeof apis]: Property;
};

export const apiCodes = Object.keys(apis).reduce(
    (prev, apiCode) => ({ ...prev, [apiCode]: apiCode }),
    {} as ApiTypes,
);
export const appConfig: AppConfig = {
    auth,
    apis,
    basename: import.meta.env.BASE_URL,
};

import {
    WorkflowActivitySpec,
    combinePlugins,
    createPluginMaps,
} from '@rf-smart-for-oraclecloud/wf-engine';
import { api } from '@rf-smart-for-oraclecloud/platform-ui';
import {
    Profile,
    WorkflowDetail,
    convertWorkflow,
} from '@rf-smart-for-oraclecloud/wf-web';
import { plugins } from '@@/plugins';
import { apiCodes } from '@@/appConfig';
import { WorkflowAggregateResponse } from '../types';

const useMock = import.meta.env.VITE_USE_MOCK_API === 'true';
const apiCode = useMock ? apiCodes.mock : apiCodes.workflows;

export const getAggregate = async () => {
    const apiResponse = await api.get<WorkflowAggregateResponse>(
        apiCode,
        `workflows/aggregate`,
    );

    return {
        profiles: mapProfiles(apiResponse.profiles),
        workflows: mapWorkflows(apiResponse.workflows),
    };
};

function mapProfiles(profiles: Profile[]): Profile[] {
    return profiles.map((p) => ({
        ...p,
        entries: p.entries.map((e) => ({
            ...e,
            pluginConfigs: [],
        })),
    }));
}

function mapWorkflows(
    workflows: Record<string, WorkflowDetail>,
): Record<string, WorkflowActivitySpec> {
    const pluginMaps = createPluginMaps(combinePlugins(...plugins));

    return Object.values(workflows).reduce(
        (result, workflow) => {
            try {
                return {
                    ...result,
                    [workflow.id]: convertWorkflow(workflow, pluginMaps),
                };
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error('error converting workflow', workflow, error);
                return result;
            }
        },
        {} as Record<string, WorkflowActivitySpec>,
    );
}

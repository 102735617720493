import {
    DebugSnapshot,
    WorkflowEvent,
    createEngine,
} from '@rf-smart-for-oraclecloud/wf-engine';
import { createAppender } from '@rf-smart-for-oraclecloud/wf-web';
import { postSnapshots, postLogs } from './api/logs';
import { plugins } from './plugins';

export function createAppEngine() {
    return createEngine({
        plugins,
        eventAppenderFactory: () =>
            createAppender<WorkflowEvent>({
                onFlush: postLogs,
                bufferSize: 10,
            }),
        snapshotAppenderFactory: () =>
            createAppender<DebugSnapshot>({
                onFlush: postSnapshots,
                bufferSize: 3,
            }),
    });
}

import {
    ActivitySpecMap,
    WorkflowActivitySpec,
    WorkflowAggregate,
} from '@rf-smart-for-oraclecloud/wf-engine';
import { ProfileEntry } from '@rf-smart-for-oraclecloud/wf-web';

export function createAggregate(
    profileEntry: ProfileEntry | undefined,
    workflows: Record<string, WorkflowActivitySpec>,
): WorkflowAggregate | undefined {
    if (profileEntry === undefined) {
        return undefined;
    }

    const workflow = workflows[profileEntry.workflowId];
    if (workflow === undefined) {
        return undefined;
    }

    const workflowMap = {
        [workflow.id]: workflow,
        ...getDependencies(workflow, workflows),
    };
    const plugins = Object.values(workflowMap).flatMap((spec) => {
        const wf = spec as WorkflowActivitySpec;
        if (wf.plugins) {
            return wf.plugins;
        }
        return [];
    });

    return {
        id: workflow.id,
        entry: workflow.id,
        with: profileEntry.with,
        name: profileEntry.name,
        plugins,
        workflowMap,
    };
}

function getDependencies(
    workflow: WorkflowActivitySpec,
    workflows: Record<string, WorkflowActivitySpec>,
): ActivitySpecMap {
    const depends = workflow.depends ?? [];
    return depends.reduce(
        (result, id) => ({
            ...result,
            [id]: workflows[id],
            ...getDependencies(workflows[id], workflows),
        }),
        {},
    );
}

import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { ApplicationStore } from './types';

export const useStore = create(
    devtools<ApplicationStore>((setState) => ({
        runData: {
            initialized: false,
            loading: false,
            workflows: {},
            profiles: [],
        },
        setRunData: (args) =>
            setState((state) => ({
                runData: {
                    ...state.runData,
                    ...args,
                },
            })),
    })),
);

export const { getState, setState } = useStore;

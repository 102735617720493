import { api } from '@rf-smart-for-oraclecloud/platform-ui';
import { WorkflowApiProvider } from '@rf-smart-for-oraclecloud/wf-engine';

const apiCode = 'mock';

export const mockApiProvider: WorkflowApiProvider = {
    name: 'Mock Api Provider',
    category: 'Mock Api Provider',
    description: 'Mock Api Provider',
    type: apiCode,
    send: (request) => {
        const { path, body, method } = request;
        const options = {
            addAuthHeader: false,
            addTenantContext: false,
        };

        return api.execute(apiCode, path, method, body, options);
    },
};

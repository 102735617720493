import oracle from '@rf-smart-for-oraclecloud/wf-plugin-oracle';
import { createPlugin } from '@rf-smart-for-oraclecloud/wf-engine';
import { builtIn } from '@rf-smart-for-oraclecloud/wf-web';
import { mockApiProvider } from './api/workflowApiProviders.ts/mock';
import { oracleApiProvider } from './api/workflowApiProviders.ts/oracle';

export const plugins = [
    oracle,
    builtIn,
    createPlugin({
        apiProviders: [oracleApiProvider, mockApiProvider],
    }),
];

import { useCallback, useEffect, useMemo, useState } from 'react';
import { ProfileEntry } from '@rf-smart-for-oraclecloud/wf-web';
import { useEngine } from './useEngine';

export function useStoreSync(profileEntry: ProfileEntry) {
    const [needsSync, setNeedsSync] = useState(false);
    const [awaitingUpload, setAwaitingUpload] = useState<number>();
    const [isSyncing, setIsSyncing] = useState(false);

    const engine = useEngine();
    const { offlineStores } = engine.getPlugins();

    const storeApis = useMemo(() => {
        const storeConfigs = profileEntry.pluginConfigs.filter(
            (config) => config.pluginType === 'offlineStore',
        );

        return storeConfigs.map((config) => ({
            api: offlineStores[config.type].store,
            with: config.with,
        }));
    }, [profileEntry, offlineStores]);

    const updateSyncValue = useCallback(() => {
        const syncMeta = storeApis.map((store) => store.api.getSyncMeta());
        setNeedsSync(syncMeta.some((meta) => !meta.ready));
        setAwaitingUpload(
            syncMeta.reduce((result, meta) => result + meta.uploadSize, 0),
        );
    }, [storeApis]);

    const handleSync = useCallback(() => {
        setIsSyncing(true);
        Promise.all(
            storeApis.map((store) => store.api.sync(store.with, engine)),
        ).then(() => {
            updateSyncValue();
            setIsSyncing(false);
        });
    }, [storeApis, updateSyncValue, engine]);

    useEffect(() => {
        updateSyncValue();
        const syncInterval = setInterval(updateSyncValue, 500);
        return () => clearInterval(syncInterval);
    }, [storeApis, updateSyncValue]);

    return {
        isOffline: storeApis.length > 0,
        isSyncing,
        awaitingUpload,
        needsSync,
        handleSync,
    };
}

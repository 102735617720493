import { apiCodes } from '@@/appConfig';
import { api } from '@rf-smart-for-oraclecloud/platform-ui';
import { WorkflowApiProvider } from '@rf-smart-for-oraclecloud/wf-engine';

const useMock = import.meta.env.VITE_USE_MOCK_API === 'true';
const apiCode = useMock ? apiCodes.mock : apiCodes.oracle;

export const oracleApiProvider: WorkflowApiProvider = {
    name: 'Oracle Api Provider',
    category: 'Oracle',
    description: 'Oracle Api Provider',
    type: 'oracle',
    send: async (request) => {
        const { path, body, method } = request;
        return api.execute(apiCode, path, method, body);
    },
};

import { Profile } from '@rf-smart-for-oraclecloud/wf-web';
import { WorkflowListItemDisplay } from './WorkflowListItemDisplay';

export function FilteredWorkflowList({
    profiles,
    loading,
}: FilteredWorkflowListProps) {
    return (
        <div style={{ opacity: loading ? '.5' : '' }}>
            {profiles.map((profile) => (
                <div
                    key={profile.id}
                    style={{
                        padding: '1rem 0',
                    }}
                >
                    <h6>{profile.name}</h6>
                    {profile.entries.map((entry) => (
                        <WorkflowListItemDisplay
                            key={entry.id}
                            profileId={profile.id}
                            profileEntry={entry}
                        />
                    ))}
                </div>
            ))}
        </div>
    );
}

type FilteredWorkflowListProps = Readonly<{
    profiles: Profile[];
    loading: boolean;
}>;

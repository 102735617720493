import { useNavigate } from 'react-router-dom';
import { Button } from '@rf-smart-for-oraclecloud/ui';
import { ProfileEntry } from '@rf-smart-for-oraclecloud/wf-web';
import { useStoreSync } from '@@/hooks/useStoreSync';
import { useAppIntl } from '@@/intl';
import './style.css';

export function WorkflowListItemDisplay({
    profileId,
    profileEntry,
}: WorkflowListItemDisplayProps) {
    const navigate = useNavigate();
    const { workflowItemDisplay } = useAppIntl();
    const handleStartClick = () =>
        navigate(`/run/${profileId}/${profileEntry.id}`);
    const { isOffline, isSyncing, awaitingUpload, needsSync, handleSync } =
        useStoreSync(profileEntry);

    const syncButtonText =
        awaitingUpload && awaitingUpload > 0
            ? `${workflowItemDisplay.sync()} ${awaitingUpload}`
            : workflowItemDisplay.sync();

    return (
        <div className="wf-list__item">
            <h5>{profileEntry.name}</h5>
            <div className="wf-list__item-description">
                {profileEntry.description}
            </div>
            <div className="wf-list__item-actions">
                {isOffline && (
                    <Button
                        type="button"
                        onClick={handleSync}
                        style={{
                            backgroundColor: awaitingUpload ? 'red' : undefined,
                        }}
                    >
                        {!isSyncing && syncButtonText}
                        {isSyncing && workflowItemDisplay.syncing()}
                    </Button>
                )}
                <Button
                    type="button"
                    onClick={handleStartClick}
                    isDisabled={isOffline && needsSync}
                >
                    {workflowItemDisplay.start()}
                </Button>
            </div>
        </div>
    );
}

type WorkflowListItemDisplayProps = Readonly<{
    profileId: string;
    profileEntry: ProfileEntry;
}>;

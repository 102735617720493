import { useEffect, useMemo } from 'react';
import { Profile } from '@rf-smart-for-oraclecloud/wf-web';
import { getAggregate } from '@@/api/workflows';
import { useStore } from '../store';

export function useRunData({ filter }: { filter?: string } = {}) {
    const runData = useStore((store) => store.runData);
    const setRunData = useStore((store) => store.setRunData);

    const { initialized, loading, profiles, workflows } = runData;

    useEffect(() => {
        if (initialized) {
            return;
        }

        (async () => {
            setRunData({ loading: true });

            try {
                const response = await getAggregate();
                setRunData(response);
            } finally {
                setRunData({
                    initialized: true,
                    loading: false,
                });
            }
        })();
    }, [initialized, setRunData]);

    const filteredProfiles = useMemo(
        () => filterProfiles(profiles, filter),
        [filter, profiles],
    );

    return {
        loading: !initialized || loading,
        profiles: filteredProfiles,
        workflows,
    };
}

function filterProfiles(profiles: Profile[], filter?: string) {
    if (filter === undefined) {
        return profiles;
    }

    const filterRegex = new RegExp(filter, 'i');

    return profiles.reduce((result, profile) => {
        const matchesEntireProfile = filterRegex.test(profile.name);

        const entries = matchesEntireProfile
            ? profile.entries
            : profile.entries.filter(
                  (entry) =>
                      filterRegex.test(entry.name) ||
                      filterRegex.test(entry.description),
              );

        if (entries.length > 0) {
            result.push({
                ...profile,
                entries,
            });
        }

        return result;
    }, [] as Profile[]);
}
